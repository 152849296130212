<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">任务列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入任务名称" class="ics" ></el-input>
                <el-input v-model="landName" placeholder="请输入宗地项目名称" class="ics" ></el-input>
                <el-input v-model="userName" placeholder="请输入作业人员名称" class="ics" v-show="sysType=='2'"></el-input>
                <el-select v-model="status" placeholder="选择任务状态"  filterable  clearable class="ics">
                        <el-option label="待分配至作业人员" value="1" ></el-option>
                        <el-option label="作业人员待提交" value="2" ></el-option>
                        <el-option label="正常提交" value="3" ></el-option>
                        <el-option label="异常标注" value="4" ></el-option>
                </el-select>
                <el-select v-model="ejdw" placeholder="选择二级单位"  filterable  clearable class="ics">
                    <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-select v-model="fallType" placeholder="是否落宗"  filterable  clearable class="ics">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button v-if="sysType=='2'" :type="downFileStatus?'danger':'warning'" @click="exportExcel">{{downFileStatus?'点击停止导出':'导出任务'}}</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" > 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <!-- <el-table-column prop="ZSBH" label="地块证书编号"></el-table-column> -->
                    <el-table-column prop="MJ_M" label="面积（亩）" ></el-table-column>
                    <el-table-column prop="LandName" label="宗地项目名称"></el-table-column>
                    <el-table-column prop="STATUS" label="任务状态" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== 0">待分配至作业单位</span>
                            <span v-else-if="scope.row.STATUS== 1">待分配至作业人员</span>
                            <span v-else-if="scope.row.STATUS== 2">作业人员待提交</span>
                            <span v-else-if="scope.row.STATUS== 3">正常提交</span>
                            <span v-else-if="scope.row.STATUS== 4">异常标注</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="GIVETIME" label="分配时间" width="180">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!= 0">{{scope.row.GIVETIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                    <el-table-column prop="WorkUserName" label="作业人员" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!= 0 && scope.row.STATUS!= 1">{{scope.row.WorkUserName}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LZCount" label="已落宗数量" width="80"></el-table-column>
                    <el-table-column prop="LASTSUBMITTIME" label="最近一次提交时间" width="180">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== 3||scope.row.STATUS== 4">{{scope.row.LASTSUBMITTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="positionPost(scope.row)" type="text" size="small" style="color:#E6A23C">现场照片</el-button>
                            <el-button @click="showPost(scope.row)" type="text" size="small" style="color:#409EFF">落宗</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="fallModal" width="1000px" :close-on-click-modal="false" top="60px">
            <el-steps  :active="step" finish-status="success" simple>
                <el-step title="输入不动产单元号"></el-step>
                <el-step title="数据确认"></el-step>
                <el-step title="数据提交"></el-step>
            </el-steps>
            <div style="overflow:hidden;" v-show="step==0">
                <div style="position: relative;overflow:hidden;margin-top:20px;">
                    <p style="margin-bottom:6px;float:left;margin-top:9px;">不动产单元号　</p>
                    <el-input v-model="bdcdyh" autocomplete="off" placeholder="请输入不动产单元号（格式为28位的数字和字母）" style="width:78%;float:left;" @blur="yzBdcdyh"></el-input>
                        <div class="dhylength" :class="bdcdyh.length==28?'dhyok':''">{{bdcdyh.length}}位 <i class="el-icon-check" v-show="bdcdyh.length==28"></i></div>
                        <el-button type="primary" @click="nextStep()" class="fr">下一步</el-button>
                </div>
                <div class="bdcdyhMsg" style="color:#f56c6c;">{{bdcdyhMsg}}</div>
            </div>
            <div style="overflow:hidden;" v-show="step==1">
                <div style="overflow:hidden;margin-top:20px;">
                    <p style="margin-bottom:6px;float:left;">不动产单元号　</p>
                    <el-input v-model="bdcdyh" autocomplete="off" placeholder="请输入不动产单元号（格式为28位的数字和字母）" style="width:78%;float:left;" disabled></el-input>
                    <el-button type="primary" @click="prevStep()" class="fr">上一步</el-button>
                </div>
                <div class="bdcdyhMsg" v-show="step==1" :class="fallDetail?'bdcdyhMsgB':'bdcdyhMsgA'">{{bdcdyhMsg}}</div>
            </div>
            
            <el-form :model="fallInfo" status-icon :rules="fallrules"  ref="fForm" label-width="200px" class="demo-uForm" style="margin-top:20px;" v-show="step==1">
                <el-form-item label="宗地名称" prop="LANDNAME">
                    <el-input v-model="fallInfo.LANDNAME" autocomplete="off" placeholder="请输入宗地名称" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid"></el-input>
                </el-form-item>
                <el-form-item label="土地用途" prop="lzlandtypesnowid">
                    <el-select v-model="fallInfo.lzlandtypesnowid" placeholder="请选择土地用途"  filterable  style="width:100%;" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="EJDW">
                    <el-select v-model="fallInfo.EJDW" placeholder="请选择二级单位"  filterable  style="width:100%;" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="省市区" prop="regions">
                    <el-cascader v-model="fallInfo.regions" ref="cascaderAddr" :options="options" :props="cityProps"  placeholder="请选择省市区"  style="width:100%" filterable :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid"></el-cascader>
                </el-form-item>
                <el-form-item label="镇(乡)">
                    <el-input v-model="fallInfo.TOWN" autocomplete="off" placeholder="请输入镇(乡)" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid"></el-input>
                </el-form-item>
                <el-form-item label="村">
                    <el-input v-model="fallInfo.VILLAGE" autocomplete="off" placeholder="请输入村" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid"></el-input>
                </el-form-item>
                <!-- <el-form-item label="不动产单元号" prop="bdcdyh" >
                    <el-input v-model="" autocomplete="off" placeholder="请输入不动产单元号（格式为28位的数字和字母）"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="有无证（批复）" prop="ishavecertificate" >
                    <el-radio v-model="fallInfo.ishavecertificate" label="1" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid">有证（批复）</el-radio>
                    <el-radio v-model="fallInfo.ishavecertificate" label="0" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid">无证（批复）</el-radio>
                </el-form-item> -->
                <el-form-item label="证书类型" prop="zstypesnowid">
                    <el-select v-model="fallInfo.zstypesnowid" placeholder="请选择证书类型"  filterable  style="width:100%;" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid" @change="zslxChange">
                        <el-option v-for="item in zslxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证书编号" prop="oldbh" v-show="notRight!=1">
                    <el-input v-model="fallInfo.oldbh" autocomplete="off" placeholder="请输入证书编号" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid"></el-input>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="lzmj" >
                    <el-input v-model="fallInfo.lzmj" autocomplete="off" placeholder="请输入落宗面积（只保留两位小数）" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid"></el-input>
                </el-form-item>
                <el-form-item label="原面积（平方米）" prop="oldmj">
                    <el-input v-model="fallInfo.oldmj" autocomplete="off" placeholder="请输入原面积，没有就不填" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid"></el-input>
                </el-form-item>
                <el-form-item label="面积变化原因" prop="changeinfo">
                    <el-input v-model="fallInfo.changeinfo" autocomplete="off" placeholder="请输入面积变化原因" :disabled="fallDetail && fallDetail.WORKUSERSNOWID != zyryid"></el-input>
                </el-form-item>
                <el-form-item label="提交人" v-if="fallDetail">
                    <el-input v-model="fallDetail.WorkUserName" disabled></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitFallForm()" v-if="step==1">提交</el-button>
                    <el-button @click="fallModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="fallListTitle" :visible.sync="fallListModal" width="1400px" :close-on-click-modal="false" top="50px">
            <div style="overflow:hidden">
                <el-input v-model="sbdcdyh" placeholder="不动产单元号" class="ics" ></el-input>
                <el-input v-model="zdmc" placeholder="宗地名称" class="ics" ></el-input>
                <el-select v-model="zdlx" placeholder="土地用途"  filterable  clearable class="ics">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="fejdw" placeholder="二级单位"  filterable  clearable class="ics">
                    <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-cascader v-model="regions"  :options="options" :props="cityPropsB"  placeholder="省市区"  class="ics" clearable  filterable> </el-cascader>
                <el-select v-model="tjr" placeholder="提交人"  filterable  clearable class="ics" v-show="sysType=='2'">
                        <el-option v-for="item in tjrList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <!-- <el-select v-model="sfyz" placeholder="是否有证（批复）"  filterable  clearable class="ics">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select> -->
                <el-select v-model="zslx" placeholder="证书类型"  filterable  clearable class="ics">
                    <el-option v-for="item in zslxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <!-- <el-select v-model="mjsfyy" placeholder="面积是否一样"  filterable  clearable class="ics">
                        <el-option label="是" value="0" ></el-option>
                        <el-option label="否" value="1" ></el-option>
                </el-select> -->
                <el-button type="primary" @click="searchFallList">查询</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="sysType=='3'">添加</el-button>

            </div>
            <el-table :data="fallList" stripe style="width: 100%" :height="fallTableHeight"> 
                    <el-table-column prop="LANDNAME" label="宗地名称"></el-table-column>
                    <el-table-column prop="LZLandTypeName" label="土地用途"></el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="REGION" label="省市区"></el-table-column>
                    <el-table-column prop="TOWN" label="镇(乡)"></el-table-column>
                    <el-table-column prop="VILLAGE" label="村"></el-table-column>
                    <el-table-column prop="BDCDYH" label="不动产单元号" width="150"></el-table-column>
                    <!-- <el-table-column prop="ISHAVECERTIFICATE" label="有无证（批复）" width="120" >
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISHAVECERTIFICATE== 1">有</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="OLDBHTYPE" label="证书类型"></el-table-column>
                    <el-table-column prop="OLDBH" label="证书编号" width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.OLDBH">{{scope.row.OLDBH}}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LZMJ" label="落宗面积"></el-table-column>
                    <el-table-column prop="OLDMJ" label="原面积">
                        <template slot-scope="scope">
                            <span v-if="scope.row.OLDMJ">{{scope.row.OLDMJ}}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CHANGEINFO" label="面积变化原因" width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CHANGEINFO">{{scope.row.CHANGEINFO}}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                    <el-table-column prop="WorkUserName" label="提交人"></el-table-column>
                    <el-table-column prop="UpdateTime" label="提交时间"></el-table-column>
                    <el-table-column label="操作" width="120" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editFall(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="sysType=='3'&&scope.row.UserCanUpdate==1">编辑</el-button>
                            <el-button @click="unbindPost(scope.row)" type="text" size="small" style="color:#409EFF" >解绑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="FPageIndex" :page-size="FPageSize" :total="FTotal" class="pg" @current-change="FPGChange"></el-pagination>
                <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
        <el-dialog title="落宗编辑" :visible.sync="fallEditModal" width="800px" :close-on-click-modal="false" >
            <el-form :model="fallInfo" status-icon :rules="fallrules"  ref="eForm" label-width="200px" class="demo-uForm">
                <el-form-item label="宗地名称" prop="LANDNAME">
                    <el-input v-model="fallInfo.LANDNAME" autocomplete="off" placeholder="请输入宗地名称" ></el-input>
                </el-form-item>
                <el-form-item label="土地用途" prop="lzlandtypesnowid">
                    <el-select v-model="fallInfo.lzlandtypesnowid" placeholder="请选择土地用途"  filterable  style="width:100%;">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="EJDW">
                    <el-select v-model="fallInfo.EJDW" placeholder="请选择二级单位"  filterable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="省市区" prop="regions">
                    <el-cascader v-model="fallInfo.regions"  :options="options" :props="cityProps"  placeholder="请选择省市区"  style="width:100%" filterable></el-cascader>
                </el-form-item>
                <el-form-item label="镇(乡)">
                    <el-input v-model="fallInfo.TOWN" autocomplete="off" placeholder="请输入镇(乡)"></el-input>
                </el-form-item>
                <el-form-item label="村">
                    <el-input v-model="fallInfo.VILLAGE" autocomplete="off" placeholder="请输入村"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fallInfo.bdcdyh" autocomplete="off" placeholder="请输入不动产单元号（格式为28位的数字和字母）" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item label="有无证（批复）" prop="ishavecertificate" >
                    <el-radio v-model="fallInfo.ishavecertificate" label="1">有证（批复）</el-radio>
                    <el-radio v-model="fallInfo.ishavecertificate" label="0">无证（批复）</el-radio>
                </el-form-item> -->
                <el-form-item label="证书类型" prop="zstypesnowid">
                    <el-select v-model="fallInfo.zstypesnowid" placeholder="请选择证书类型"  filterable  style="width:100%;" @change="zslxChange">
                        <el-option v-for="item in zslxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证书编号" prop="oldbh" v-show="notRight!= 1">
                    <el-input v-model="fallInfo.oldbh" autocomplete="off" placeholder="请输入证书编号"></el-input>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="lzmj" >
                    <el-input v-model="fallInfo.lzmj" autocomplete="off" placeholder="请输入落宗面积（只保留两位小数）"></el-input>
                </el-form-item>
                <el-form-item label="原面积（平方米）" prop="oldmj">
                    <el-input v-model="fallInfo.oldmj" autocomplete="off" placeholder="请输入原面积，有证时必填"></el-input>
                </el-form-item>
                <el-form-item label="面积变化原因" prop="changeinfo">
                    <el-input v-model="fallInfo.changeinfo" autocomplete="off" placeholder="请输入面积变化原因,一致时不必填"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitFallEditForm()">提交</el-button>
                    <el-button @click="fallEditModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="现场照片" :visible.sync="positionModal" width="800px" :close-on-click-modal="false" >
            <TianMap ref="tmap"  :move="false" :draw="true" :width="olWidth" :height="olHeight" @mapFinish="tianmapFinishA"></TianMap>
            <div class="imgRow" v-if="taskImgs.length>0"> 
                <el-image v-for="url in taskImgs" :key="url" :src="url" :preview-src-list="[url]" lazy  style="width: 100px; height: 100px;margin-left:10px;" >
                </el-image>
            </div>
        </el-dialog>
    </div>
</template>
<script>
var h = document.body.clientHeight
import TianMap from "../../components/OpenLayers"
export default {
  name: "Task",
  components:{
    TianMap,
  },
  data() {
    var LANDNAME = (rule, value, callback) => {
        if (value === "" || value == null) {
        callback(new Error("请输入宗地名称"));
      } else {
        callback();
      }
    };

    var EJDW = (rule, value, callback) => {
        if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var zstypesnowid = (rule, value, callback) => {
        if (value === "" || value == null) {
        callback(new Error("请选择证书类型"));
      } else {
        callback();
      }
    };


    var regions = (rule, value, callback) => {
      if (value.length!= 3 || value == null) {
        callback(new Error("请选择正确的省市区"));
      } else {
        callback();
      }
    };
    
    var lzmj=(rule, value, callback) => {
        if (value === "" || value == null) {
            callback(new Error("请输入面积"));
        } else if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value != "" && value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };

    var oldmj = (rule, value, callback) => {
        if (this.notRight !=1 && value === "" || value == null) {
            callback(new Error("请输入原面积"));
        }else if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value != "" && value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };

    var changeinfo = (rule, value, callback) => {
      if (this.fallInfo.lzmj!= this.fallInfo.oldmj &&   value === "" ) {
        callback(new Error("请输入面积变化原因"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
        if (value === "" || value == null) {
            callback(new Error("请输入不动产单元号"));
        }else if(value.length != 28 || !/^[a-zA-Z0-9]+$/.test(value)){
            callback(new Error('不动产单元号只能由28位的数字和字母组成'));
        }else {
        callback();
      }
    };
 
    var lzlandtypesnowid = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择土地用途"));
      } else {
        callback();
      }
    };

    // var ishavecertificate = (rule, value, callback) => {
    //   if (value === "" || value == null) {
    //     callback(new Error("请选择有无证（批复）"));
    //   } else {
    //     callback();
    //   }
    // };

    var oldbh= (rule, value, callback) => {
      if (this.notRight!=1 &&  value === "") {
        callback(new Error("请输入证书编号"));
      } else {
        callback();
      }
    };


    return {
        sysType:"",
        zyryid:"",
        zydwid:"",
        zydwmc:"",

        status:"3",
        canDo:true,
        showPagination:false,
        name:"",
        userName:"",
        landName:"",
        ejdwList:[],
        ejdw:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
        fallType:"",
        zdlxList:[],
        zslxList:[],
        doTypeStr:"",
        doType:"",
        
        options:[],
        cityProps:{
            value:'name',   
            label:'name',  
            children: 'children'   
        },
        cityPropsB:{
            value:'name',   
            label:'name',  
            children: 'children',
            checkStrictly:true,
        },

        step:0,        
        bdcdyh:"",
        bdcdyhMsg:"",

        fallEditModal:false,
        taskInfo:null,
        fallModal:false,
        fallInfo:{},
        fallrules: {
            LANDNAME: [{ validator: LANDNAME, trigger: "blur" }],
            EJDW: [{ validator: EJDW, trigger: "blur" }],
            zstypesnowid: [{ validator: zstypesnowid, trigger: "blur" }],
            regions: [{ validator: regions, trigger: "blur" }],
            lzmj: [{ validator: lzmj, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            lzlandtypesnowid:[{ validator: lzlandtypesnowid, trigger: "blur" }],
            // ishavecertificate:[{ validator: ishavecertificate, trigger: "blur" }],
            oldbh:[{ validator: oldbh, trigger: "blur" }],
            oldmj:[{ validator: oldmj, trigger: "blur" }],
            changeinfo:[{ validator: changeinfo, trigger: "blur" }],
        },
        notRight:1,



        fallTableHeight:0,
        sbdcdyh:"",
        tjr:"",
        tjrList:[],
        sfyz:"",
        mjsfyy:"",
        zdlx:"",
        zdmc:"",
        fejdw:"",
        zslx:"",
        regions:[],
        FPageIndex:1,
        FPageSize:10,
        FTotal:0,
        fallDetail:null,    
        fallList:[],
        fallListModal:false,
        fallListTitle:"",

        positionModal:false,
        tianmap:null,
        olWidth:760,
        olHeight:300,
        taskImgs:[],


        downFileStatus:false,
    };
  },
  mounted() {
    this.fallTableHeight = h - 280
    this.sysType=this.$store.state.sysType  
    if(this.sysType=="2"){
        this.getZydwid()
        this.zydwmc=this.$store.state.name
    }else if(this.sysType=="3"){
        this.zyryid=this.$store.state.snowid  
        this.getPostList()
    }

    this.options=this.comjs.citys
    this.loadItem=null
    this.getZdlx()
    this.getEjdwList()
    this.getZslxList()
  },
  methods: {
    tianmapFinishA(){
        this.tianmap=this.$refs.tmap.map
    },
    zslxChange(e){
      this.notRight=1
      if(e!=""){
        for(var i=0;i<this.zslxList.length;i++){
            if(this.zslxList[i].SnowID == e){
                this.notRight=this.zslxList[i].NotRight
                break;
            }
        }
      } 
    },
    getZslxList(){
        this.$http.post("ZSType/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.zslxList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getZdlx(){
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
            })
    },
    getZdlxNameById(id){
        var name=""
        for(var i=0;i<this.zdlxList.length;i++){
            if(this.zdlxList[i].SnowID== id){
                name=this.zdlxList[i].Name
            }
        }
        return name
    },
    getZydwid(){
        this.canDo=false
        this.$http.post("WorkUnit/GetDataList", {name:this.$store.state.name})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].SNOWID
                    this.getPostList()
                    this.getzzryList(this.zydwid)
                    this.canDo=true
                }else{
                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    getzzryList(unitid){
        this.$http.post("WorkUser/GetDataList", {parentSnowID:unitid})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.tjrList=response.data.DATA
                }
            })
    },
    //获取任务
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            name:this.name,
            stutas:this.status,
            workUnitName:"",
            workUserName:this.userName,
            landName:this.landName,
            WorkUnitSnowID:this.zydwid,
            WorkUserSnowID:this.zyryid,
            giveStartTime:"",
            giveEndTime:"",
            LastSubmitStartTime:"",
            LastSubmitEndTime:"",
            type:"2",
            landSnowID:"",
            EJDW:this.ejdw,
            HaveExchangeResult:"",
            ISHAVELZ:this.fallType,
        }
        this.$http.post("Task/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },



    //添加落宗
    addPost(){
        this.step=0
        this.bdcdyh=""
        this.bdcdyhMsg=""
        this.doTypeStr=this.taskInfo.NAME+"落宗"
        this.doType="2"
        this.fallDetail=null
        this.fallInfo={}
        this.fallModal=true
    },
    yzBdcdyh(){
        this.bdcdyhMsg=""
        if(this.bdcdyh==''){
            this.bdcdyhMsg="请输入不动产单元号"
        }else if(this.bdcdyh.length!= 28){
            this.bdcdyhMsg="请输入正确的不动产单元号"
        }
        
    },
    nextStep(){
        if(this.bdcdyh.length== 28){
            this.fallDetail=null
            this.getFallDetail()
        }
    },
    prevStep(){
        this.step=0
        this.fallDetail=null
        this.bdcdyhMsg=""
    },
    
    getFallDetail(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("LandLZ/GetDataList", {BDCDYH:this.bdcdyh})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    this.flagFallTaskBind(d)
                }else{
                    this.getLandDetail()
                }
            }).catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });

    },
    flagFallTaskBind(d){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            lzSnowID:d.SnowID,
            taskSnowID:this.taskInfo.SNOWID,
        }
        this.$http.post("LandLZ/IsTaskBindLZ", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.step=0
                    this.bdcdyhMsg="此任务已经与该单元号对应的落宗信息绑定，请输入其他单元号"
                } else {
                    var oldmj=''
                        if(d.OLDMJ && d.OLDMJ!=''){
                            oldmj=d.OLDMJ
                        }
                        var oldbh=''
                        if(d.OLDBH && d.OLDBH!=''){
                            oldbh=d.OLDBH
                        }
                        var changeinfo=''
                        if(d.CHANGEINFO && d.CHANGEINFO!=''){
                            changeinfo=d.CHANGEINFO
                        }
                        var xz=''
                        if(d.TOWN && d.TOWN!=''){
                            xz=d.TOWN
                        }
                        var c=''
                        if(d.VILLAGE && d.VILLAGE!=''){
                            c=d.VILLAGE
                        }

                        
                        this.zslxChange(d.ZSTYPESNOWID)
                        this.fallDetail=d
                        this.fallInfo={
                            snowid:d.SnowID,
                            LANDNAME:d.LANDNAME,
                            EJDW:d.EJDW,
                            TOWN:xz,
                            VILLAGE:c,
                            regions:d.REGION.split("/"),
                            lzmj:d.LZMJ,
                            bdcdyh:this.bdcdyh,
                            lzlandtypesnowid:d.LZLANDTYPESNOWID,
                            ishavecertificate:d.ISHAVECERTIFICATE.toString(),
                            zstypesnowid:d.ZSTYPESNOWID,
                            oldbh:oldbh,
                            oldmj:oldmj,
                            changeinfo:changeinfo,
                        }
                        this.step=1
                        this.bdcdyhMsg="通过此单元号查询到以下落宗信息，请确认后点击提交"
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    getLandDetail(){
        this.fallInfo={
            LANDNAME:"",
            EJDW:"",
            TOWN:"",
            VILLAGE:"",
            zstypesnowid:"",
            regions:[],
            lzmj:"",
            bdcdyh:this.bdcdyh,
            lzlandtypesnowid:"",
            ishavecertificate:"0",
            oldbh:"",
            oldmj:"",
            changeinfo:"",
            tasksnowid:this.taskInfo.SNOWID
        }
        this.notRight=1
        this.$http.post("Land/GetDataList", {snowID:this.taskInfo.LANDSNOWID})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    if(d.MJ && d.MJ!=''){
                        this.fallInfo.oldmj=d.MJ
                    }
                    if(d.PROVINCES && d.PROVINCES!='' && d.CITY && d.CITY!='' && d.DISTRICT && d.DISTRICT!=''){
                        this.fallInfo.regions=[d.PROVINCES,d.CITY,d.DISTRICT]
                    }
                    if(d.ZSBH && d.ZSBH!=''){
                        this.fallInfo.ishavecertificate="1"
                        this.fallInfo.oldbh=d.ZSBH
                    }
                    // if(d.DISPLAYNAME && d.DISPLAYNAME!=''){
                    //     this.fallInfo.LANDNAME=d.DISPLAYNAME
                    // }
                    if(d.EJDW && d.EJDW!=''){
                        this.fallInfo.EJDW=d.EJDW
                    }
                    
                }
            })
        this.step=1
        this.bdcdyhMsg="此单元号未查到落宗信息，请完善信息后再提交"
    },

    submitFallForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    if(this.fallDetail && this.fallDetail.WORKUSERSNOWID !=this.zyryid){
                        this.bindTaskAndFall(this.fallDetail.SnowID,'1')
                    }else{
                        var sfyz='0'
                        var oldbh=''
                        if(this.notRight!=1){
                            oldbh=this.fallInfo.oldbh
                            sfyz='1'
                        }
                        
                        var lzmj=this.comjs.toFixedPrice(this.fallInfo.lzmj)


                        var oldmj=''
                        if(this.fallInfo.oldmj !=''){
                            oldmj=this.comjs.toFixedPrice(this.fallInfo.oldmj)
                        }

                        var changeinfo=''
                        if(oldmj != '' && oldmj != lzmj){
                            changeinfo=this.fallInfo.changeinfo
                        }

                        var info={
                            LANDNAME:this.fallInfo.LANDNAME,
                            EJDW:this.fallInfo.EJDW,
                            TOWN:this.fallInfo.TOWN,
                            VILLAGE:this.fallInfo.VILLAGE,
                            zstypesnowid:this.fallInfo.zstypesnowid,
                            region:this.fallInfo.regions[0]+"/"+this.fallInfo.regions[1]+"/"+this.fallInfo.regions[2],
                            lzmj:lzmj,
                            bdcdyh:this.fallInfo.bdcdyh,
                            lzlandtypesnowid:this.fallInfo.lzlandtypesnowid,
                            ishavecertificate:sfyz,
                            oldbh:oldbh,
                            oldmj:oldmj,
                            changeinfo:changeinfo,
                        }
                        if(this.fallInfo.tasksnowid!= undefined && this.fallInfo.tasksnowid!=""){
                            info.tasksnowid=this.fallInfo.tasksnowid
                        }
                        if(this.fallInfo.snowid!= undefined && this.fallInfo.snowid!=""){
                            info.snowid=this.fallInfo.snowid
                        }
                        this.affirmFAddModal(info)
                    }
                   
                } else {
                    return false;
                }
            });
        }
    },
    affirmFAddModal(info){
        this.$confirm('请确保落宗信息无误在进行提交', '提示', {
                confirmButtonText: '确定提交',
                cancelButtonText: '我再看看',
                type: 'warning'
            }).then(() => {
                this.sendFAdd(info)
            })
    },
    sendFAdd(info){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("LandLZ/InsertOrUpdateData", info)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    if(info.tasksnowid!= undefined && info.tasksnowid!=""){
                        this.searchFallListFun()
                    }else{
                        this.bindTaskAndFall(info.snowid,'0')
                    }
                    this.$message.success("已提交")
                    this.fallModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    bindTaskAndFall(fallId,type){
        this.canDo=false
        var params={
            lzSnowID:fallId,
            taskSnowID:this.taskInfo.SNOWID,
        }
        this.$http.post("LandLZ/TaskBingLZ", params)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.searchFallListFun()
                    if(type=="1"){
                        this.fallModal=false
                        this.$message.success("已绑定")
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
        //
        
    },
    editFall(d){
        var oldmj=''
        if(d.OLDMJ && d.OLDMJ!=''){
            oldmj=d.OLDMJ
        }
        this.zslxChange(d.ZSTYPESNOWID)
        var sfyz='0'
        var oldbh=''
        if(this.notRight!=1 && d.OLDBH && d.OLDBH!=''){
            oldbh=d.OLDBH
            sfyz='1'
        }

        var changeinfo=''
        if(d.CHANGEINFO && d.CHANGEINFO!=''){
            changeinfo=d.CHANGEINFO
        }

        var x=''
        if(d.TOWN && d.TOWN!=''){
            x=d.TOWN
        }

        var c=''
        if(d.VILLAGE && d.VILLAGE!=''){
            c=d.VILLAGE
        }

        
        this.fallDetail=null
        this.fallInfo={
            LANDNAME:d.LANDNAME,
            EJDW:d.EJDW,
            TOWN:x,
            VILLAGE:c,
            zstypesnowid:d.ZSTYPESNOWID,
            snowid:d.SnowID,
            regions:d.REGION.split("/"),
            lzmj:d.LZMJ,
            bdcdyh:d.BDCDYH,
            lzlandtypesnowid:d.LZLANDTYPESNOWID,
            ishavecertificate:d.ISHAVECERTIFICATE.toString(),
            oldbh:oldbh,
            oldmj:oldmj,
            changeinfo:changeinfo,
        }
        this.fallEditModal=true
    },
    submitFallEditForm() {
        if (this.canDo) {
            this.$refs.eForm.validate((valid) => {
                if (valid) {
                    var sfyz='0'
                    var oldbh=''
                    if(this.notRight!=1){
                        oldbh=this.fallInfo.oldbh
                        sfyz='1'
                    }
                    var lzmj=this.comjs.toFixedPrice(this.fallInfo.lzmj)
                    var oldmj=''
                    if(this.fallInfo.oldmj !=''){
                        oldmj=this.comjs.toFixedPrice(this.fallInfo.oldmj)
                    }

                    var info={
                        LANDNAME:this.fallInfo.LANDNAME,
                        EJDW:this.fallInfo.EJDW,
                        TOWN:this.fallInfo.TOWN,
                        VILLAGE:this.fallInfo.VILLAGE,
                        zstypesnowid:this.fallInfo.zstypesnowid,
                        region:this.fallInfo.regions[0]+"/"+this.fallInfo.regions[1]+"/"+this.fallInfo.regions[2],
                        lzmj:lzmj,
                        bdcdyh:this.fallInfo.bdcdyh,
                        lzlandtypesnowid:this.fallInfo.lzlandtypesnowid,
                        ishavecertificate:sfyz,
                        oldbh:oldbh,
                        oldmj:oldmj,
                        changeinfo:this.fallInfo.changeinfo,
                        snowid:this.fallInfo.snowid,
                    }
                    this.affirmFEditModal(info)
                } else {
                    return false;
                }
            });
        }
    },
    affirmFEditModal(info){
        this.$confirm('请确保落宗信息无误在进行提交', '提示', {
                confirmButtonText: '确定提交',
                cancelButtonText: '我再看看',
                type: 'warning'
            }).then(() => {
                this.sendFEdit(info)
            })
    },
    sendFEdit(info){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("LandLZ/InsertOrUpdateData", info)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已提交")
                    this.fallEditModal=false
                    this.searchFallListFun()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    unbindPost(item){
        if(this.canDo){
            this.$confirm('确定解绑？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendUnbind(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendUnbind(id){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            lzSnowID:id,
            taskSnowID:this.taskInfo.SNOWID
        }
        this.$http.post("LandLZ/TaskUnBingLZ", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已解绑")
                    this.searchFallListFun()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },

    exportExcel(){
        if(this.canDo){
            if(this.downFileStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.downFileStatus=false
                })
            }else{
                this.$confirm('导出前可以根据查询条件进行筛选，确定执行导出吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendExportExcel()
                })
            }
            
        }
    },
    sendExportExcel(){
        this.downFileStatus=true
        var params={
            name:this.name,
            stutas:this.status,
            workUnitName:this.zydwmc,
            workUserName:this.userName,
            landName:this.landName,
            EJDW:this.ejdw,
            type:"2",
        }
        this.$download.post("ExportExcel/ExportTaskList", params,{ responseType: 'blob',timeout:300000})
            .then((resJson) => {
                if(this.downFileStatus){
                    if (resJson) {
                        var namenew=this.getFileName()
                        let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                        if (window.navigator.msSaveOrOpenBlob) {
                            if (this.form.name) {
                                window.navigator.msSaveBlob(blob, this.form.name + '.xls')
                            } else {
                            window.navigator.msSaveBlob(blob, namenew+".xls")
                            }
                        } else {
                            let downloadElement = document.createElement('a')
                            let href = window.URL.createObjectURL(blob) 
                            downloadElement.href = href
                            downloadElement.download = namenew+".xls"
                            document.body.appendChild(downloadElement)
                            let evt = document.createEvent('MouseEvents')
                            evt.initEvent('click', false, false)
                            downloadElement.dispatchEvent(evt)
                            document.body.removeChild(downloadElement)
                        }
                        this.$message.success('文件已导出!')
                    } else {
                        this.$message.error("数据导出失败")
                    }
                }
                this.downFileStatus=false
            })
            .catch((error) => {
                if (error.isAxiosError) {
                   if(error.response.status==403){
                    this.$message.error("此账号暂无导出权限")
                   }
                }
                this.downFileStatus=false
                this.hideLoading()
            });
    },
    getFileName(){
        var name=""
        if(this.name!=""){
            name+=this.name+"_"
        }
        if(this.landName!=""){
            name+=this.landName+"_"
        }
        if(this.unitName!=""){
            name+=this.unitName+"_"
        }
        if(this.userName!=""){
            name+=this.userName+"_"
        }
        if(this.ejdw!=""){
            name+=this.ejdw+"_"
        }

        if(this.status!=""){
            if(this.status=="0" || this.status=="1"){
                name+="待分配"+"_"
            }else if(this.status=="2"){
                name+="待提交"+"_"
            }else if(this.status=="3"){
                name+="正常提交"+"_"
            }else if(this.status=="4"){
                name+="异常标注"+"_"
            }
        }

        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t='任务清单_'+y.toString()+m.toString()+d.toString()
        name+=t
        return name
    },
    //显示落宗记录表
    showPost(item){
        this.taskInfo=item
        this.fallListTitle=item.NAME+"-落宗记录表"
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=1
        this.FTotal=0
        this.sbdcdyh=""
        this.tjr=""
        this.sfyz=""
        this.mjsfyy=""
        this.zdlx=""
        this.zdmc=""
        this.fejdw=""
        this.zslx=""
        this.searchFallListFun()
        this.fallListModal=true
    },
    searchFallList(){
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=1
        this.FTotal=0
        this.searchFallListFun()
    },
    FPGChange(e){
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=e
        this.FTotal=0
        this.searchFallListFun()
    },
    searchFallListFun(){
        this.showLoading("请稍后")
        var region=''
        if(this.regions && this.regions.length>0){
            for(var i=0;i<this.regions.length;i++){
                if(i==0){
                    region=this.regions[i]
                }else{
                    region=region+"/"+this.regions[i]
                }
            }
        }
        var params={
            tasksnowid:this.taskInfo.SNOWID,
            BDCDYH:this.sbdcdyh,
            pageIndex:this.FPageIndex,
            pageSize:this.FPageSize,
            region:region,
            submitworkunitsnowid:this.zydwid,
            submitworkusersnowid:this.tjr,
            LZLandTypeSnowID:this.zdlx,
            isHaveCertificate:this.sfyz,
            mjIsInconsistent:this.mjsfyy,
            EJDW:this.fejdw,
            LandName:this.zdmc,
            zstypesnowid:this.zslx,
        }
        this.$http.post("LandLZ/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.fallList=response.data.DATA
                    this.FTotal=response.data.TOTALCOUNT
                } else {
                    this.fallList=[]
                    this.FTotal=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.fallList=[]
                this.FTotal=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    //现场照片
    positionPost(item){
        this.taskImgs=[]
        this.getTaskInfo(item.SNOWID)
    },
    getTaskInfo(id){
        this.$http.post("Task/GetAllDataListWithZB", {snowID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    if(d.Files.length>0){
                        this.positionModal=true
                        setTimeout(()=>{
                            if(this.tianmap){
                                this.$refs.tmap.clearDraw()
                            }
                            var  c=[]
                            if(d.LON && d.LAT){
                                c=[d.LON,d.LAT]
                            }else {
                                if(d.Files.length>0){
                                    if(d.Files[0].Lon && d.Files[0].Lat){
                                        c=[d.Files[0].Lon,d.Files[0].Lat]
                                    }
                                }
                            }
                            this.$refs.tmap.moveTo(c,16)
                            this.setTaskImgs(d.Files)
                        },500)
                    }else{
                        this.$message.error("暂无现场照片")
                    }
   
                    
                }else{
                    this.$message.error("暂无现场照片")
                }
            })
    },
    setTaskImgs(list){
        for(var i=0;i<list.length;i++){
            var d=list[i]
            this.taskImgs.push(d.Path)
            this.$refs.tmap.appendTaskMarker(d)
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
  .olmap{width: 760px;height: 300px;overflow: hidden;}
  .imgRow{overflow: hidden;margin-top: 10px;}
  .dhylength{font-size:13px;color:#ADADAD;position: absolute;right: 122px;top:11px;}
  .bdcdyhMsg{font-size:12px;height:30px;line-height:30px;}
  .dhyok{color:#67C23A}
  .bdcdyhMsgB{color:#67C23A;}
  .bdcdyhMsgB{color:#E6A23C;}
</style>