<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">汇交记录列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入宗地名称" class="iw" ></el-input>
                <el-select v-model="zdlx" placeholder="选择宗地类型"  filterable  clearable class="iw">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="ejdwsel" placeholder="选择二级单位"  filterable  class="iww" collapse-tags multiple  @change="changeEjdwSel" v-show="sysType=='5'">
                        <el-option v-for="item in ejdwSelList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-select v-model="tjr" placeholder="选择提交人"  filterable  clearable class="iw" v-show="sysType=='2'">
                        <el-option v-for="item in tjrList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="fzdw" placeholder="选择发证地区"  filterable  clearable class="iw">
                        <el-option v-for="item in fzdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-select v-model="cgzt" placeholder="成果审核状态"  filterable  clearable class="iw" >
                        <el-option label="待审核" value="0" ></el-option>
                        <el-option label="作业单位已确认" value="2" ></el-option>
                        <el-option label="油田方已确认" value="3" ></el-option>
                        <el-option label="需修改" value="4" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    
                    <el-table-column prop="STATUS" label="审核状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== '0'" style="color:#909399;">待审核</span>
                            <span v-else-if="scope.row.STATUS== '2'" style="color:#409EFF;">作业单位已确认</span>
                            <span v-else-if="scope.row.STATUS== '3'" style="color:#67C23A;">油田方已确认</span>
                            <span v-else-if="scope.row.STATUS== '4'" style="color:#F56C6C;">需修改</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LandStatus" label="审核失败原因">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== '4'" style="color:#F56C6C;">{{scope.row.CONFIRMINFO}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LandName" label="宗地名称"></el-table-column>
                    <el-table-column prop="LandTypeName" label="宗地类型"></el-table-column>
                    <el-table-column prop="LandStatus" label="地块现状">
                        <template slot-scope="scope">
                            <span v-if="scope.row.LandStatus== '1'">正常</span>
                            <span v-else-if="scope.row.LandStatus== '2'">侵占</span>
                            <span v-else-if="scope.row.LandStatus== '3'">耕地</span>
                            <span v-else-if="scope.row.LandStatus== '4'">无井</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="SYDW" label="使用单位"></el-table-column>
                    <el-table-column prop="ZSBH" label="证书编号"></el-table-column>
                    <el-table-column prop="QLR" label="权利人"></el-table-column>
                    <el-table-column prop="GYQK" label="共有情况"></el-table-column>
                    <el-table-column prop="ZL" label="坐落"></el-table-column>
                    <el-table-column prop="BDCDYH" label="不动产单元号"></el-table-column>
                    <el-table-column prop="QLLX" label="权利类型"></el-table-column>
                    <el-table-column prop="QLXZ" label="权利性质"></el-table-column>
                    <el-table-column prop="YT" label="用途"></el-table-column>
                    <el-table-column prop="MJ" label="面积（㎡）"></el-table-column>
                    <el-table-column prop="SYQX" label="使用期限"></el-table-column>
                    <el-table-column prop="QLQTZK" label="权利其他状况"></el-table-column>
                    <el-table-column prop="FZDW" label="发证地区"></el-table-column>
                    <!-- <el-table-column prop="CAD" label="CAD源文件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CAD !=''" @click="downloadFile(scope.row.CAD)" style="color:#409EFF;cursor:pointer;">点击下载</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column>
                    <el-table-column prop="PDF" label="证书扫描件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.PDF !=''" @click="downloadFile(scope.row.PDF)" style="color:#409EFF;cursor:pointer;">点击查看</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="SubmitUserName" label="提交人"></el-table-column>
                    
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="scope.row.STATUS!='2' && scope.row.STATUS!='3' && sysType=='4'  && scope.row.SubmitUserSnowID ==tjr">编辑</el-button>
                        <el-button @click="delFruit(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="scope.row.STATUS!='2' && scope.row.STATUS!='3' && sysType=='4' && scope.row.SubmitUserSnowID ==tjr">删除</el-button>
                        <el-button @click="auditingDWItem(scope.row)"  type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS=='0' && sysType=='2'">审核</el-button>
                        <el-button @click="auditingYTItem(scope.row)"  type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS=='2' && sysType=='5'">审核</el-button>
                        <el-button @click="showDetail(scope.row)"  type="text" size="small" style="color:#67C23A">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="fruitModal" width="800px" :close-on-click-modal="false" top="20px">
            <el-form :model="fruitInfo" status-icon :rules="fruitrules"  ref="fForm" label-width="200px" class="demo-uForm">
                <el-form-item label="宗地名称" prop="landName">
                    <el-input v-model="fruitInfo.landName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="宗地类型" prop="landTypeSnowID">
                    <el-select v-model="fruitInfo.landTypeSnowID" placeholder="请选择宗地类型"  filterable  style="width:100%;">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地块现状" prop="landStatus">
                    <el-select v-model="fruitInfo.landStatus" placeholder="请选择地块现状"  filterable  style="width:100%;">
                        <el-option value="1" label="正常"></el-option>
                        <el-option value="2" label="侵占"></el-option>
                        <el-option value="3" label="耕地"></el-option>
                        <el-option value="4" label="无井"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw">
                    <el-select v-model="fruitInfo.ejdw" placeholder="请选择二级单位"  filterable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="使用单位" prop="sydw">
                    <el-input v-model="fruitInfo.sydw" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="不动产证书编号" prop="zsbh">
                    <el-input v-model="fruitInfo.zsbh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利人" prop="qlr">
                    <el-input v-model="fruitInfo.qlr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="共有情况" prop="gyqk">
                    <el-input v-model="fruitInfo.gyqk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="坐落" prop="zl">
                    <el-input v-model="fruitInfo.zl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fruitInfo.bdcdyh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利类型" prop="qllx">
                    <el-input v-model="fruitInfo.qllx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利性质" prop="qlxz">
                    <el-input v-model="fruitInfo.qlxz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用途" prop="yt">
                    <el-input v-model="fruitInfo.yt" autocomplete="off"></el-input>
                </el-form-item>
               
                <el-form-item label="使用期限" prop="syqx">
                    <el-input v-model="fruitInfo.syqx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利其他状况">
                    <el-input v-model="fruitInfo.qlqtzk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发证地区" prop="fzdws">
                    <el-cascader v-model="fruitInfo.fzdws" ref="cascaderAddr" :options="options" :props="cityProps"  placeholder="请选地区"  style="width:100%"></el-cascader>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="mj">
                    <el-input v-model="fruitInfo.mj" autocomplete="off"></el-input>
                </el-form-item>
                <!-- prop="txt" -->
                <el-form-item label="地块范围坐标文件" >
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="uploadFunB"
                        :before-upload="beforeAvatarUploadB">
                        <span v-if="txtFileName!= ''" style="color:#409EFF;">{{txtFileName}}</span>
                        <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="txtFileName!= ''" type="danger" size="mini" @click="deleteFileB()">取消选择</el-button>
                </el-form-item>
                <el-form-item label="证书扫描件上传" prop="smj">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="uploadFunC"
                        :before-upload="beforeAvatarUploadC">
                        <span v-if="smjFileName!= ''" style="color:#409EFF;">{{smjFileName}}</span>
                        <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="smjFileName!= ''" type="danger" size="mini" @click="deleteFileC()">取消选择</el-button>
                </el-form-item>
                <el-form-item label="CAD源文件上传">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="uploadFunD"
                        :before-upload="beforeAvatarUploadD">
                        <span v-if="cadFileName!=''" style="color:#409EFF;">{{cadFileName}}</span>
                        <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="cadFileName!=''" type="danger" size="mini" @click="deleteFileD()">取消选择</el-button>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitFruitForm()">提交</el-button>
                    <el-button @click="fruitModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="成果详情" :visible.sync="detailModal" width="1400px" :close-on-click-modal="false" top="20px" class="detailBox">
            <div class="con">
                <div class="left" :style="{height:(pdfHeight+40)+ 'px'}">
                    <div class="blockTitle">
                        基础信息
                    </div>
                    <div class="blockCon" v-if="detailModal">
                        <div class="strRow">宗地名称：{{detailItem.LandName}}</div> 
                        <div class="strRow">证书编号：{{detailItem.ZSBH}}</div> 
                        <div class="strRow">不动产单元号：{{detailItem.BDCDYH}}</div> 
                        <div class="strRow strRowh">宗地类型：{{detailItem.LandTypeName}}</div> 
                        <div class="strRow strRowh">面积：{{detailItem.MJ}}㎡</div> 
                        <div class="strRow strRowh">发证地区：{{detailItem.FZDW}}</div> 
                        <div class="strRow strRowh">
                            地块现状：
                            <span v-if="detailItem.LandStatus== '1'">正常</span>
                            <span v-else-if="detailItem.LandStatus== '2'">侵占</span>
                            <span v-else-if="detailItem.LandStatus== '3'">耕地</span>
                            <span v-else-if="detailItem.LandStatus== '4'">无井</span>
                        </div> 
                        <div class="strRow strRowh">二级单位：{{detailItem.EJDW}}</div> 
                        <div class="strRow strRowh">使用单位：{{detailItem.SYDW}}</div> 
                        <div class="strRow strRowh">权利人：{{detailItem.QLR}}</div> 
                        <div class="strRow strRowh">共有情况：{{detailItem.GYQK}}</div> 
                        <div class="strRow strRowh">坐落：{{detailItem.ZL}}</div> 
                        <div class="strRow strRowh">用途：{{detailItem.YT}}</div> 
                        <div class="strRow strRowh">权利类型：{{detailItem.QLLX}}</div> 
                        <div class="strRow strRowh">权利性质：{{detailItem.QLXZ}}</div> 
                        <div class="strRow">权利其他状况：{{detailItem.QLQTZK}}</div> 
                    </div>
                    <div class="blockTitle" v-if="showtdt">
                        现场位置
                    </div>
                    <div class="blockCon" v-if="showtdt"> 
                        <TianMap ref="tmap"  :move="false" :draw="true" :width="olWidth" :height="olHeight" @mapFinish="tianmapFinishA"></TianMap>
                        <div class="imgRow" v-if="taskImgs.length>0"> 
                            <el-image v-for="url in taskImgs" :key="url" :src="url" :preview-src-list="[url]" lazy  style="width: 100px; height: 100px;margin-left:10px;" >
                            </el-image>
                        </div>
                    </div>
                    <div class="blockTitle" v-if="showzbt">
                        坐标范围
                    </div>
                    <div class="blockCon" v-if="showzbt">
                        
                    </div>
                </div>
                <div class="right" >
                    <div class="blockTitle" v-if="fallDetail">
                        落宗详情
                    </div>
                    <div class="blockCon" v-if="fallDetail">
                        <div class="strRow strRowh">宗地类型：{{fallDetail.LZLandTypeName}}</div> 
                        <div class="strRow strRowh">行政区：{{fallDetail.REGION}}</div> 
                        <div class="strRow">不动产单元号：{{fallDetail.BDCDYH}}</div> 
                        <div class="strRow strRowh" v-if="fallDetail.ishavecertificate==1">有无证（批复）：有</div> 
                        <div class="strRow strRowh" v-if="fallDetail.ishavecertificate!=1">有无证（批复）：无</div> 
                        <div class="strRow strRowh" v-if="fallDetail.ishavecertificate==1">原证编号（批复编号）：{{fallDetail.OLDBH}}</div> 
                        <div class="strRow" style="height:1px;"></div> 
                        <div class="strRow strRowh">面积：{{fallDetail.LZMJ}}㎡</div> 
                        <div class="strRow strRowh">原面积：{{fallDetail.OLDMJ}}</div> 
                        <div class="strRow"  v-if="fallDetail.OLDMJ && fallDetail.OLDMJ!='' && fallDetail.OLDMJ != fallDetail.LZMJ">面积变化原因：{{fallDetail.CHANGEINFO}}</div> 
                        <div class="strRow strRowh">提交单位/人员：{{fallDetail.WorkUnitName}}/{{fallDetail.WorkUserName}}</div> 
                        <div class="strRow strRowh">提交时间：{{fallDetail.UpdateTime}}</div> 
                    </div>
                    <div class="blockTitle">
                        证书扫描件
                    </div>
                    <embed :src="pdfSrc" type="application/pdf" width="100%" :height="fallDetail?pdfHeight-150:pdfHeight" />
                </div>
            </div> 
        </el-dialog>

        <el-dialog :title="auditingTitle" :visible.sync="auditingModal" width="800px" :close-on-click-modal="false">
            <el-form :model="auditingInfo" status-icon :rules="auditingrules"  ref="aForm" label-width="120px" class="demo-uForm">
                <el-form-item label="审核结果">
                    <el-radio v-model="auditingInfo.confirmResult" label="0">通过</el-radio>
                    <el-radio v-model="auditingInfo.confirmResult" label="1">不通过</el-radio>
                </el-form-item>
                <el-form-item label="不通过原因" prop="confirmInfo" v-show="auditingInfo.confirmResult=='1'">
                    <el-input v-model="auditingInfo.confirmInfo" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="sendAuditing()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h = document.body.clientHeight
import TianMap from "../../components/OpenLayers"
export default {
  name: "RegionManage",
  components:{
    TianMap,
  },
  data() {
    var landName=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入宗地名称"));
      } else {
        callback();
      }
    };

    var landTypeSnowID=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择宗地类型"));
      } else {
        callback();
      }
    };

    var landStatus = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择地块现状"));
      } else {
        callback();
      }
    };

    var ejdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var sydw=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用单位"));
      } else {
        callback();
      }
    };

    var qlr = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利人"));
      } else {
        callback();
      }
    };

    var gyqk = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入共有情况"));
      } else {
        callback();
      }
    };

    var zl = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入坐落"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入不动产单元号"));
      } else {
        callback();
      }
    };

    var qllx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利类型"));
      } else {
        callback();
      }
    };

    var qlxz = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利性质"));
      } else {
        callback();
      }
    };

    var yt = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入用途"));
      } else {
        callback();
      }
    };
    
    var mJ_M=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };
    var syqx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用期限"));
      } else {
        callback();
      }
    };

    var fzdws= (rule, value, callback) => {
      if (value.length!=3) {
        callback(new Error("请输入发证单位"));
      } else {
        callback();
      }
    };

    var smj=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传证书扫描件"));
      } else {
        callback();
      }
    };

    var txt=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传地块范围坐标文件"));
      } else {
        callback();
      }
    };

    var zsbh=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入土地证（不动产证）号"));
      } else {
        callback();
      }
    };

    var confirmInfo=(rule, value, callback) => {
      if (this.auditingInfo.confirmResult=="1" && value === "" || value == null) {
        callback(new Error("请输入不通过原因"));
      } else {
        callback();
      }
    };

    return {
        usersnowid:"",
        sysType:"",
        zydwid:"",


        canDo:true,
        showPagination:false,
        ejdwstr:"",
        ejdwsel:[],
        ejdwSelList:[],
        fzdw:"",
        name:"",
        tjr:"",
        tjrList:[],
        cgzt:"",
        zdlx:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        zdlxList:[],
        doTypeStr:"",
        doType:"",
        ejdwList:[],
        fzdwList:[],
        fruitModal:false,
        fruitInfo:{},
        fruitrules: {
            landName: [{ validator: landName, trigger: "blur" }],
            landTypeSnowID: [{ validator: landTypeSnowID, trigger: "blur" }],
            landStatus: [{ validator: landStatus, trigger: "blur" }],
            ejdw: [{ validator: ejdw, trigger: "blur" }],
            sydw: [{ validator: sydw, trigger: "blur" }],
            zsbh: [{ validator: zsbh, trigger: "blur" }],
            qlr: [{ validator: qlr, trigger: "blur" }],
            gyqk: [{ validator: gyqk, trigger: "blur" }],
            zl: [{ validator: zl, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            qllx: [{ validator: qllx, trigger: "blur" }],
            qlxz: [{ validator: qlxz, trigger: "blur" }],
            yt: [{ validator: yt, trigger: "blur" }],
            mj: [{ validator: mJ_M, trigger: "blur" }],
            syqx: [{ validator: syqx, trigger: "blur" }],
            fzdws: [{ validator: fzdws, trigger: "blur" }],
            smj:[{ validator: smj, trigger: "blur" }],
            // txt:[{ validator: txt, trigger: "blur" }],
        },
        friuitLandName:"",


        txtArea:0,
        txtFile:null,
        txtFileSrc:"",
        txtFileId:"",
        txtFileName:"",
        chooseTxtFile:false,
        smjFile:null,
        smjFileSrc:"",
        smjFileId:"",
        smjFileName:"",
        chooseSmjFile:false,
        cadFile:null,
        cadFileSrc:"",
        cadFileId:"",
        cadFileName:"",
        chooseCadFile:false,


        detailItem:null,
        detailModal:false,
        pdfHeight:"600px",
        pdfSrc:"",
        txtSrc:"",
        cadSrc:"",
        showtdt:false,
        showzbt:false,
        taskImgs:[],

        auditingModal:false,
        auditingTitle:"",
        auditingInfo:{
            exchangeResultSnowID:"",
            confirmResult:"0",
            confirmInfo:"",
        },
        auditingrules:{
            confirmInfo: [{ validator: confirmInfo, trigger: "blur" }],
        },

        options:[],
        cityProps:{
            value:'name',   
            label:'name',  
            children: 'children'   
        },

        tianmap:null,
        olWidth:680,
        olHeight:300,

        fallDetail:{},
    };
  },

  mounted() {
    this.options=this.comjs.citys
    this.pdfHeight = (h - 170)+"px"
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.landName
        this.ejdw=getQueryData.ejdw
        this.fzdw=getQueryData.fzdw
    }
    this.loadItem=null
    this.getEjdwList()
    this.getFzdwList()
    this.getZdlx()
    this.sysType=this.$store.state.sysType  
    if(this.sysType=="2"){
        this.getzzdwA()
    }else if(this.sysType=="4"){
        this.getzzdwB()
        this.tjr=this.$store.state.snowid
    }else if(this.sysType=="5"){
        this.getejdwqx()
    }
  },
  methods: {
    tianmapFinishA(){
        this.tianmap=this.$refs.tmap.map
    },
    changeEjdwSel(e){
        var str=""
        var list=[]
        if(e.length==0){
            this.ejdwsel=this.ejdwSelList
            list=this.ejdwSelList
        }else {
            list =e
        }
        for(var i=0;i<list.length;i++){
            if(i==0){
                str=list[i]
            }else{
                str=str+","+list[i]
            }
        }
        this.ejdwstr=str
    },
    getZdlx(){
        this.canDo=false
        this.$http.post("LandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
            })
    },
    getZdlxNameById(id){
        var name=""
        for(var i=0;i<this.zdlxList.length;i++){
            if(this.zdlxList[i].SnowID== id){
                name=this.zdlxList[i].Name
            }
        }
        return name
    },
    getzzdwA(){
        this.canDo=false
        this.$http.post("WorkUnit/GetDataList", {name:this.$store.state.name})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].SNOWID
                    this.canDo=true
                    this.getFruitUserList(this.zydwid)
                    this.getPostList()
                }else{

                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                       this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    getFruitUserList(id){
        this.$http.post("ResultUser/GetDataList", {WorkUnitSnowID:id})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.tjrList=response.data.DATA
                }
            })
    },
    getzzdwB(){
        this.canDo=false
        this.$http.post("ResultUser/GetDataList", {snowID:this.$store.state.snowid})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].WorkUnitSnowID
                    this.canDo=true
                    this.getPostList()
                }else{
                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                       this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    getejdwqx(){
        this.canDo=false
        this.$http.post("OilUser/GetOilUserEJDW", {oilUserSnowID:this.$store.state.snowid})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var ejdw=response.data.DATA[0].EJDWSTR
                    if(ejdw!=""){
                        this.ejdwstr=ejdw
                        this.ejdwsel=ejdw.split(",")
                        this.ejdwSelList=ejdw.split(",")
                    } 
                    this.canDo=true
                }
                this.getPostList()
            })
    },
    downloadFile(src){
        window.open(src)
    },
    getFzdwList(){
        this.$http.post("ExchangeResult/GetDistrictList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fzdwList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            ejdw:this.ejdwstr,
            fzdw:this.fzdw,
            landName:this.name,
            submitUserSnowID:this.tjr,
            status:this.cgzt,
            landTypeSnowID:this.zdlx,
            workUnitSnowID:this.zydwid,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("ExchangeResult/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    editPost(item){
        this.txtFile=null
        this.txtFileSrc=""
        this.txtFileId=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.txtArea=0

        this.smjFile=null
        this.smjFileSrc=""
        this.smjFileId=""
        this.smjFileName=""
        this.chooseSmjFile=false

        this.cadFile=null
        this.cadFileSrc=""
        this.cadFileId=""
        this.cadFileName=""
        this.chooseCadFile=false

        this.friuitLandName=item.DISPLAYNAME
        this.doTypeStr=item.LandName+"成果汇交登记"

        var  smj=""
        var  txt=""
        for(var i=0;i<item.Files.length;i++){
            if(item.Files[i].ImageType ==2){
                smj="1"
                this.smjFileName="证书扫描件.pdf"
                this.smjFileSrc=item.Files[i].Path
                this.smjFileId=item.Files[i].SnowID
            }else if(item.Files[i].ImageType ==3){
                this.cadFileName="CAD源文件.dwg"
                this.cadFileSrc=item.Files[i].Path
                this.cadFileId=item.Files[i].SnowID
            }else if(item.Files[i].ImageType ==7){
                txt="1"
                this.txtFileName="坐标范围文件.txt"
                this.txtFileSrc=item.Files[i].Path
                this.txtFileId=item.Files[i].SnowID
            }
        }
        var fzdws=[]
        if(item.FZDW.indexOf("/")!= -1){
            fzdws=item.FZDW.split("/")
        }
        this.fruitInfo={
            SnowID:item.SnowID,
            landName:item.LandName,
            landTypeSnowID:item.LandTypeSnowID,
            landSnowID:item.LandSnowID,
            landStatus:item.LandStatus,
            submitUserSnowID:this.$store.state.snowid,
            ejdw:item.EJDW,
            sydw:item.SYDW,
            zsbh:item.ZSBH,
            qlr:item.QLR,
            gyqk:item.GYQK,
            zl:item.ZL,
            bdcdyh:item.BDCDYH,
            qllx:item.QLLX,
            qlxz:item.QLXZ,
            yt:item.YT,
            mj:item.MJ,
            syqx:item.SYQX,
            qlqtzk:item.QLQTZK,
            fzdw:item.FZDW,
            fzdws:fzdws,
            smj:smj,
            txt:txt,
        }

        this.fruitModal=true
    },
    submitFruitForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    delete  this.fruitInfo.smj
                    delete  this.fruitInfo.txt
                    if(this.fruitInfo.fzdws.length==3){
                        this.fruitInfo.fzdw=this.fruitInfo.fzdws[0]+"/"+this.fruitInfo.fzdws[1]+"/"+this.fruitInfo.fzdws[2]
                    }
                    //delete  this.fruitInfo.fzdws
                    this.sendFEdit()
                } else {
                    return false;
                }
            });
        }
    },
    sendFEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/UpdateData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已提交")
                    this.fruitModal=false
                    this.getPostList()
                    if(this.chooseTxtFile){
                        this.addImgB(this.fruitInfo.SnowID)
                    }
                    if(this.chooseSmjFile){
                        this.addImgC(this.fruitInfo.SnowID)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(this.fruitInfo.SnowID)
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    delFruit(item){
        if(this.canDo){
            this.$confirm('确定删除地块'+item.LandName+'的成果汇交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDelFruit(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelFruit(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.fruitModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    beforeAvatarUploadC(file) {
        const isJPG = file.type === 'application/pdf';
        if (!isJPG) {
        this.$message.error('证书扫描件只能是 PDF  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.smjFileSrc = _URL.createObjectURL(file);
            this.chooseSmjFile=true
            this.smjFile = file
            this.smjFileName=file.name
            this.fruitInfo.smj="1"
        }
        return isJPG;
    },
    uploadFunC(file){
        //console.log(file)
    },
    beforeAvatarUploadD(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('CAD源文件件只能是 DWG  格式!');
        }else{
            if(file.name.split(".")[1] !="dwg"){
                this.$message.error('CAD源文件件只能是 DWG  格式!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.cadFileSrc = _URL.createObjectURL(file);
                this.chooseCadFile=true
                this.cadFile = file
                this.cadFileName=file.name
            }
        }
        return isJPG;
    },
    uploadFunD(file){
        //console.log(file)
    },
    beforeAvatarUploadB(file) {
        const isJPG = file.type === 'text/plain';
        if (!isJPG) {
        this.$message.error('坐标范围文件只能是 TXT  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.txtFileSrc = _URL.createObjectURL(file);
            this.chooseTxtFile=true
            this.txtFile = file
            this.txtFileName=file.name
            this.fruitInfo.txt="1"
        }
        return isJPG;
    },
    uploadFunB(file){
        this.txtArea=0
        //计算面积
    },
    deleteFileB(){
        this.txtFile =null
        this.txtFileSrc=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.fruitInfo.txt=""

        if( this.txtFileId!=""){
            this.sendDelFile(this.txtFileId)
        }
    },
    deleteFileC(){
        this.smjFile =null
        this.smjFileSrc=""
        this.smjFileName=""
        this.chooseSmjFile=false
        this.fruitInfo.smj=""

        if( this.smjFileId!=""){
            this.sendDelFile(this.smjFileId)
        }
    },
    deleteFileD(){
        this.cadFile =null
        this.cadFileSrc=""
        this.cadFileName=""
        this.chooseCadFile=false
        if( this.cadFileId!=""){
            this.sendDelFile(this.cadFileId)
        }
    },
    sendDelFile(id){
        this.$http.post("File/DeleteFile", {SnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },

    addImgB(id){
        var fd = new FormData();
        fd.append("file", this.txtFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=7&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    addImgC(id){
        var fd = new FormData();
        fd.append("file", this.smjFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=2&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    addImgD(id){
        var fd = new FormData();
        fd.append("file", this.cadFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=3&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },



    auditingDWItem(item){
        this.auditingTitle=item.LandName+"作业单位审核"
        this.doType="1"
        this.auditingInfo={
            exchangeResultSnowID:item.SnowID,
            confirmResult:"0",
            confirmInfo:"",
        }
        this.auditingModal=true
    },
    auditingYTItem(item){
        this.auditingTitle=item.LandName+"油田方审核"
        this.doType="2"
        this.auditingInfo={
            exchangeResultSnowID:item.SnowID,
            confirmResult:"0",
            confirmInfo:"",
        }
        this.auditingModal=true
    },

    sendAuditing(){
        if(this.canDo) {
            this.$refs.aForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendDWAuditing()
                    }else if(this.doType=="2"){
                        this.sendYTAuditing()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    sendDWAuditing(){
        var ly=""
        if(this.auditingInfo.confirmResult=="1"){
            ly=this.auditingInfo.confirmInfo
        }
        var params={
            exchangeResultSnowID: this.auditingInfo.exchangeResultSnowID,
            confirmResult: this.auditingInfo.confirmResult,
            confirmInfo:ly,
        }
        this.$http.post("ExchangeResult/WorkUnitConfirm", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("审核已提交")
                    this.auditingModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true 
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }  
             });
    },
    sendYTAuditing(){
        var ly=""
        if(this.auditingInfo.confirmResult=="1"){
            ly=this.auditingInfo.confirmInfo
        }
        var params={
            exchangeResultSnowID: this.auditingInfo.exchangeResultSnowID,
            confirmResult: this.auditingInfo.confirmResult,
            confirmInfo:ly,
        }
        this.$http.post("ExchangeResult/OilUserConfirm", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("审核已提交")
                    this.auditingModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true 
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }  
             });
    },

    showDetail(item){
        this.detailItem=item
        this.pdfSrc=""
        this.txtSrc=""
        this.cadSrc=""
        this.showtdt=false
        this.showzbt=false
        this.taskImgs=[]
        for(var i=0;i<item.Files.length;i++){
            if(item.Files[i].ImageType==2){
                this.pdfSrc=item.Files[i].Path
            }else if(item.Files[i].ImageType==3){
                this.cadSrc=item.Files[i].Path
            }else if(item.Files[i].ImageType==7){
                this.txtSrc=item.Files[i].Path
            }
        }
        this.getTaskInfo()
        this.getFallInfo(item.TaskSnowID)
        this.detailModal=true
    },
    getTaskInfo(){
        this.$http.post("Task/GetAllDataListWithZB", {snowID:this.detailItem.TaskSnowID})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    this.showtdt=true
                    setTimeout(()=>{
                        if(this.tianmap){
                            this.$refs.tmap.clearDraw()
                        }
                        var  c=[d.LON,d.LAT]
                        this.$refs.tmap.moveTo(c,16)
                        this.setTaskImgs(d.Files)
                    },500)
                    
                }
            })
    },
    setTaskImgs(list){
        for(var i=0;i<list.length;i++){
            var d=list[i]
            this.taskImgs.push(d.Path)
            this.$refs.tmap.appendTaskMarker(d)
        }
    },
    getFallInfo(taskid){
        this.fallDetail=null
        this.$http.post("LandLZ/GetDataList", {tasksnowid:taskid})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.fallDetail=response.data.DATA[0]
                }
            })
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

  .detailBox{overflow: hidden;}
  .detailBox .left{width: 680px;overflow: auto;float:left;}
  .detailBox .right{width: 660px;overflow: hidden;float:left;margin-left: 20px;}
  .detailBox .blockTitle{height: 40px;line-height: 40px;overflow: hidden;font-size: 16px;color: #409EFF;font-weight: bold;}
  .detailBox .blockCon{overflow: hidden;}
  .detailBox .strRow{font-size: 14px;color: #333333;overflow: hidden;width: 100%;float:left;white-space: nowrap;text-overflow: ellipsis;}
  .detailBox .strRowh{width: 50%!important;} 
  .detailBox .olmap{width: 680px;height: 300px;overflow: hidden;}
  .detailBox .imgRow{overflow: hidden;margin-top: 10px;}
</style>