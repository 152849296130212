<template>
  <div id="container">
    <div class="partBox">
      <div class="login_box">
        <div class="login_nav">
            <p class="login_title" :class="navType=='3'?'login_navOn':''" @click="changeNavType('3')">app扫码登录</p>
            <p class="login_title" :class="navType=='1'?'login_navOn':''" @click="changeNavType('1')">账号登录</p>
            <p class="login_title" :class="navType=='2'?'login_navOn':''" @click="changeNavType('2')">Android应用</p>
        </div>
        <div v-if="navType=='1'" style="overflow:hidden;">
          <div class="loginTitMsg">可用于作业单位、汇交用户和油田用户账号登录</div>
          <div class="login_row">
                <i class="fa fa-user"></i>
                <input
                  type="text"
                  id="LOGINNAME"
                  class="login_input"
                  placeholder="请输入用户名"
                  autocomplete="off"
                  v-model="name"
                />
              </div>
              <div class="login_row">
                <i class="fa fa-lock"></i>
                <input
                  type="password"
                  id="PASSWORD"
                  class="login_input"
                  placeholder="请输入密码"
                  autocomplete="off"
                  v-model="pwd"
                />
              </div>
              <p class="login_error">{{ errorMsg }}</p>
              <button
                id="loginBtn"
                class="login_btn"
                :class="canLogin ? '' : 'loginBtn_doing'"
                v-on:click="doLogin"
                v-loading.fullscreen.lock="fullscreenLoading"
              >
                登　　录
              </button>
        </div>
        <div v-else-if="navType=='3'" class="codeBox">
          <img :src="loginCodeUrl" alt="" v-if="loginCodeUrl!=''">
            <p>二维码将在{{countDown}}秒后刷新，<span class="refCode" v-on:click="getQrcodeUrl">立即刷新</span>  </p>
        </div>
        <div v-else-if="navType=='2'" class="codeBox">
            <img src="statics/img/apkCode.png" alt="">
            <p>使用手机浏览器扫描下载安装</p>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import qrcode from "qrcode";
export default {
  name: "Login",
  data() {

    return {
      freeLogin:"0",
      loginType:"",
      fullscreenLoading: false,
      errorMsg: "",
      canLogin: true,
      name: "",
      pwd: "",
      navType:'1',
      today:"",
      loginCodeUrl:"",
      loginCodeTime:"",
      rollCodeStatus:false,
      loginCodeStr:"",
      countDown:0,
      countDownTimer:null,

    };
  },
  mounted() {
    this.today=this.comjs.getDateMinStrByDate(new Date)
    this.loadItem=null 
    this.initUserInfo()
  },
  methods: {
    generateRandomCode(length) {
      let code = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters[randomIndex];
      } 
      return code;
    },
    getQrcodeUrl(){
      this.loginCodeTime=new Date().getTime().toString()
      this.loginCodeStr=this.loginCodeTime+"_"+this.generateRandomCode(8)
      this.createQrCodeImg()
    },
    createQrCodeImg(){
      qrcode.toDataURL(this.loginCodeStr, {
            version:"",// 二维码版本。如果未指定，将计算更合适的值。
            errorCorrectionLevel:"M", // 纠错级别。low, medium, quartile, high , L, M, Q, H
            maskPattern: 2, // 0、1、2、3、4、5、6、7
            toSJISFunc(){}, // 
            margin:1, // 边距
            scale:4, // 每一个黑点的宽度
            width:1, // 二维码宽
            'color.dark':"#000000ff", // 二维码颜色
            'color.light':"#ffffffff" // 背景色
        })
        .then(url => {
            this.loginCodeUrl=url
            this.countDown=90
            this.rollCodeStatus=true
            if(this.countDownTimer){
              clearTimeout(this.countDownTimer);
            }
            this.flagQrCodeStatus()
            this.getQrCodeLoginStatus(this.loginCodeTime)
        })
        .catch(() => {
            this.$toast("二维码生成失败");
        });
    },
    flagQrCodeStatus(){
      if(this.rollCodeStatus && this.navType =='3'){
          if(this.countDown>0){
            this.countDownTimer=setTimeout(() => {
              this.countDown--
              this.flagQrCodeStatus()
            },1000);
          }else{
            this.getQrcodeUrl()
          }
      }
    },

    getQrCodeLoginStatus(timestr){
        this.$http.get("ScanLogin/StartLogin?requestid="+this.loginCodeStr, {})
            .then((response) => {
              if(this.navType=='3' && timestr===this.loginCodeTime){
                  var nowTimer=new Date().getTime()
                  var createTimer=parseInt(timestr)
                  if(nowTimer - createTimer < 60000){
                    this.rollCodeStatus=false
                    if(this.countDownTimer){
                      clearTimeout(this.countDownTimer);
                    }
                    if(response.data.SUCCESS){
                      this.doQrCodeLoginSuccess(response.data.DATA)
                    }else{
                      this.$message.error("登录取消，自动更新二维码")
                      this.getQrcodeUrl()
                    }
                  }
              }
            })
    },

    doQrCodeLoginSuccess(data){
      this.showLoading("app扫码登录成功，页面跳转中...")
      setTimeout(() => {
        this.$store.commit('setSysType', data.SysUser.TYPE); 
        this.$store.commit('setToken', data.Token); 
        this.$store.commit('setAdmin', "1");  
        this.$store.commit('setSnowid', data.SysUser.SNOWID);  
        this.$store.commit('setName', data.SysUser.DISPLAYNAME);
        this.$store.commit('setLoginName', data.SysUser.LOGINNAME);
        this.$store.commit('setPassword', '');

        this.$cookies.set("cghj_setToday",this.today)
        this.$cookies.set("cghj_setSysType",data.SysUser.TYPE)
        this.$cookies.set("cghj_setToken",data.Token)
        this.$cookies.set("cghj_setAdmin","1")
        this.$cookies.set("cghj_setSnowid",data.SysUser.SNOWID)
        this.$cookies.set("cghj_setName",data.SysUser.DISPLAYNAME)
        this.$cookies.set("cghj_setLoginName",data.SysUser.LOGINNAME)
        this.$cookies.set("cghj_setPassword",'')
        localStorage.setItem("cghj_token", data.Token);
        this.$router.replace("/TaskList");
        this.hideLoading()
      },3000)
      
    },
    changeNavType(type){
      this.navType=type
      if(this.countDownTimer){
        clearTimeout(this.countDownTimer);
      }
      if(type==3){
          this.getQrcodeUrl()
      }
    },
    initUserInfo(){
        var loginValid=false
        if(this.$cookies.isKey("cghj_setToday") && this.$cookies.get("cghj_setToday") == this.today && this.$cookies.isKey("cghj_setSysType")  && this.$cookies.isKey("cghj_setToken")){
            loginValid=true
            this.$store.commit('setToken', this.$cookies.get("cghj_setToken"));  
            this.$store.commit('setAdmin', this.$cookies.get("cghj_setAdmin"));  
            this.$store.commit('setSnowid', this.$cookies.get("cghj_setSnowid"));
            this.$store.commit('setName', this.$cookies.get("cghj_setName"));
            this.$store.commit('setLoginName', this.$cookies.get("cghj_setLoginName"));
            this.$store.commit('setPassword', this.$cookies.get("cghj_setPassword"));
            localStorage.setItem("cghj_token", this.$cookies.get("cghj_setToken"));
        }
        if(loginValid){
          var admin=this.$cookies.get("cghj_setAdmin")
          var sysType = this.$cookies.get("cghj_setSysType")
          this.$store.commit('setSysType', sysType); 
          if(admin=="1"){
              if(this.$store.state.openPage !=""){
                this.$router.replace("/"+ this.$store.state.openPage);
              }else{
                if(sysType=="2"|| sysType=="3"){
                  this.$router.replace("/TaskList");
                }else if(sysType=="4"){
                  this.$router.replace("/LandTaskManage");
                }else if(sysType=="5"){
                  this.$router.replace("/FallList");
                }
              }
          }else{
              this.logout(false)
          }
        }else{
          this.logout(false)
        }
    },


    doFreeLogin(){
      var token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoi5rWL6K-VIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiIxODM0NDc1MTU5ODcyOTk5NDI0IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjpbIlN5c1VzZXIiLCJXb3JrVXNlciJdLCJVc2VyVHlwZSI6IjMiLCJQYXJlbnRTbm93SUQiOiIxODE2MDI0NDQ4Mjc3NTQ5MDU2IiwiZXhwIjoxNzI5ODE4NzcyLCJpc3MiOiJMSFlUIiwiYXVkIjoiTEhZVCJ9.6C4uyjBZsxP6zHkK0aqgbVEAh5o4ddPlJC7M8hvlqsY'
      this.$store.commit('setSysType', "3"); 
      this.$store.commit('setToken', token); 
      this.$store.commit('setAdmin', "1");  
      this.$store.commit('setSnowid', '1834475159872999424');  
      this.$store.commit('setName', '测试人员');
      this.$store.commit('setLoginName', 'ceshi');
      this.$store.commit('setPassword', '123456');

      this.$cookies.set("cghj_setToday",this.today)
      this.$cookies.set("cghj_setSysType","3")
      this.$cookies.set("cghj_setToken",token)
      this.$cookies.set("cghj_setAdmin","1")
      this.$cookies.set("cghj_setSnowid",'1834475159872999424')
      this.$cookies.set("cghj_setName",'测试人员')
      this.$cookies.set("cghj_setLoginName",'ceshi')
      this.$cookies.set("cghj_setPassword",'123456')
      localStorage.setItem("cghj_token", token);
      this.$router.replace("/TaskList");
    },


    doLogin() {
      if (this.canLogin) {
        if (this.name == "" || this.pwd == "") {
          if(this.freeLogin=="1"){
            this.doFreeLogin()
          }else{
            this.errorMsg = "用户名或密码不能为空";
          }
          
        } else {
          this.fullscreenLoading = true;
          this.errorMsg = "";
          this.canLogin = false;
          var params = {
            loginName: this.name,
            passWord: this.pwd,
          };
          this.$http.post("SysUser/WorkUnitLoginIn", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    this.$store.commit('setSysType', "2"); 
                    this.$store.commit('setToken', d.Token); 
                    this.$store.commit('setAdmin', "1");  
                    this.$store.commit('setSnowid', d.SysUser.SNOWID);  
                    this.$store.commit('setName', d.SysUser.DISPLAYNAME);
                    this.$store.commit('setLoginName', d.SysUser.LOGINNAME);
                    this.$store.commit('setPassword', this.pwd);

                    this.$cookies.set("cghj_setToday",this.today)
                    this.$cookies.set("cghj_setSysType","2")
                    this.$cookies.set("cghj_setToken",d.Token)
                    this.$cookies.set("cghj_setAdmin","1")
                    this.$cookies.set("cghj_setSnowid",d.SysUser.SNOWID)
                    this.$cookies.set("cghj_setName",d.SysUser.DISPLAYNAME)
                    this.$cookies.set("cghj_setLoginName",d.SysUser.LOGINNAME)
                    this.$cookies.set("cghj_setPassword",this.pwd)
                    localStorage.setItem("cghj_token", d.Token);
                    this.$router.replace("/TaskList");
                    
              } else {
                 this.doLoginHJ()
              }
            })
            .catch((error) => {
                this.canLogin = true;
                this.fullscreenLoading = false;
                this.$message.error("登录失败，请稍后再试");
            });
        }
      }
    },
    doLoginHJ() {
          var params = {
            loginName: this.name,
            passWord: this.pwd,
          };
          this.$http.post("SysUser/ResultUserLoginIn", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                    var d=response.data.DATA;
                    this.$store.commit('setSysType', "4"); 
                    this.$store.commit('setToken', d.Token); 
                    this.$store.commit('setAdmin', "1");  
                    this.$store.commit('setSnowid', d.SysUser.SNOWID);  
                    this.$store.commit('setName', d.SysUser.DISPLAYNAME);
                    this.$store.commit('setLoginName', d.SysUser.LOGINNAME);
                    this.$store.commit('setPassword', this.pwd);

                    this.$cookies.set("cghj_setToday",this.today);
                    this.$cookies.set("cghj_setSysType","4");
                    this.$cookies.set("cghj_setToken",d.Token);
                    this.$cookies.set("cghj_setAdmin","1");
                    this.$cookies.set("cghj_setSnowid",d.SysUser.SNOWID);
                    this.$cookies.set("cghj_setName",d.SysUser.DISPLAYNAME);
                    this.$cookies.set("cghj_setLoginName",d.SysUser.LOGINNAME);
                    this.$cookies.set("cghj_setPassword",this.pwd);
                    localStorage.setItem("cghj_token", d.Token);
                    this.$router.replace("/LandTaskManage"); 
              } else {
                  this.doLoginYT()
              }
            })
            .catch((error) => {
                this.canLogin = true;
                this.fullscreenLoading = false;
                this.$message.error("登录失败，请稍后再试");
            });
    },


    doLoginYT() {
      var params = {
        loginName: this.name,
        passWord: this.pwd,
      };
      this.$http.post("SysUser/OilUserLoginIn", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    this.$store.commit('setSysType', "5"); 
                    this.$store.commit('setToken', d.Token); 
                    this.$store.commit('setAdmin', "1");  
                    this.$store.commit('setSnowid', d.SysUser.SNOWID);  
                    this.$store.commit('setName', d.SysUser.DISPLAYNAME);
                    this.$store.commit('setLoginName', d.SysUser.LOGINNAME);
                    this.$store.commit('setPassword', this.pwd);

                    this.$cookies.set("cghj_setToday",this.today)
                    this.$cookies.set("cghj_setSysType","5")
                    this.$cookies.set("cghj_setToken",d.Token)
                    this.$cookies.set("cghj_setAdmin","1")
                    this.$cookies.set("cghj_setSnowid",d.SysUser.SNOWID)
                    this.$cookies.set("cghj_setName",d.SysUser.DISPLAYNAME)
                    this.$cookies.set("cghj_setLoginName",d.SysUser.LOGINNAME)
                    this.$cookies.set("cghj_setPassword",this.pwd)
                    localStorage.setItem("cghj_token", d.Token);
                    this.$router.replace("/FallList");
              } else {
                  this.fullscreenLoading = false;
                  this.canLogin = true;
                  var msg=''
                  if(response.data.MSG){
                    msg=response.data.MSG
                  }else{
                    msg="账号或密码不正确"
                  }
                  this.$message.error(msg);
              }
            })
            .catch((error) => {
                this.canLogin = true;
                this.fullscreenLoading = false;
                this.$message.error("登录失败，请稍后再试");
            });
    },


  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
input::-webkit-input-placeholder {
  color: #fff;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1200px;
  min-height: 600px;
  position: relative;
}
#container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../../public/statics/img/ubg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 666;
}
.partBox {
  width: 410px;
  overflow: hidden;
  border: 0;
  margin: 18% auto;
}
.codeBox{
  width: 300px;
  margin: 0px auto;
}
.codeBox img{
  display:block;
  margin:20px auto 0 auto;
  width: 200px;
  overflow: hidden;
}
.codeBox p{
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 10px;
  text-align: center;
}
.options-login,
.options-register {
  width: 400px;
  padding: 0 5px;
  font-size: 14px;
  position: absolute;
  display: none;
  z-index: 999;
}
.options-login .register,
.options-login .forget {
  float: right;
  margin-left: 20px;
  cursor: pointer;
  color: #2dd1b1;
}

.options-register .login {
  color: #fff;
  float: right;
  margin-left: 20px;
}
.options-register .login a {
  cursor: pointer;
  color: #2dd1b1;
}

.login_box {
  width: 400px;
  overflow: hidden;
  padding: 0 5px;
}
.login_row {
  width: 300px;
  height: 46px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  margin:0 auto 20px auto;
}
.login_row i {
  font-size: 16px;
  color: #fff;
  float: left;
  margin-top: 16px;
  margin-left: 16px;
}
.login_row input {
  width: 256px;
  height: 30px;
  line-height: 31px;
  font-size: 15px;
  float: left;
  margin-top: 8px;
  margin-left: 16px;
  background-color: transparent;
  border: 0;
  color: #fff;
  outline: none;
  
}
.active {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.login_row_last {
  margin-bottom: 0 !important;
}
.login_error {
  height: 32px;
  font-size: 14px;
  color: #ff4e44;
  line-height: 35px;
}
.login_btn {
  background-color: rgba(44, 209, 177, 0.8);
  color: #fff;
  font-size: 16px;
  width: 300px;
  height: 46px;
  border-radius: 20px;
  cursor: pointer;
  line-height: 47px;
  border: 0;
  margin:0 auto;
  display:block;
}
.login_nav{
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
}
.login_navOn{
  color: rgb(44, 209, 177)!important;
  border-bottom:2px solid rgb(44, 209, 177)!important;
  font-weight: bold;
}
.login_title {
  font-size: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  width: 33.33%;
  float:left;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}
.login_row select {
  width: 400px;
  display: inline-block;
  margin-right: 20px;
  height: 45px;
  border-color: #aaa;
  border-radius: 4px;
  outline: none;
}
.login_doing {
  color: #33cc99 !important;
}
.loginBtn_doing {
  cursor: wait, auto !important;
  background-color: rgba(187, 190, 196, 0.8);
}
.refCode{
  cursor:pointer;
  color:#E6A23C;
}
.loginTitMsg{
  font-size:14px;text-align:center;color:#2cd1b1;margin-bottom:10px;
}
</style>

