<template>
  <div class="content">
      <div class="round">
          <img src="statics/img/map/left.png" class="left" title="向左"  @click="moveToLeft">
          <img src="statics/img/map/right.png" class="right" title="向右" @click="moveToRight">
          <img src="statics/img/map/bottom.png" class="bottom" title="向下" @click="moveToBottom">
          <img src="statics/img/map/top.png" class="top" title="向上" @click="moveToTop">
      </div>
      <el-slider v-model="value" vertical height="180px" :min="1" :max="15" :step="1" :show-stops="true" class="sliderItem"></el-slider>
      <div class="xzqItem sheng" @click="setZoom(2)">省</div>
      <div class="xzqItem shi" @click="setZoom(5)">市</div>
      <div class="xzqItem qu" @click="setZoom(7)">区</div>
      <div class="xzqItem zheng" @click="setZoom(10)">镇</div>
  </div>
</template>
<style>
  .el-slider__runway{background-color: #CCCCCC!important;}
  .sliderItem{margin-top: 6px;margin-left: 17px;}
  .round{width: 73px;height: 73px;border-radius: 100%;background-color: rgba(255,255,255,0.8);position: relative;box-shadow: 0 0 5px rgba(0,0,0,0.2);}
  .round img{cursor: pointer;width: 36px;position: absolute;z-index: +20;}
  .round .top{top: -7px;left:18px;}
  .round .bottom{bottom: -7px;left: 18px;}
  .round .left{top: 19px;left: -7px;}
  .round .right{top: 19px;right: -7px;}
  .content{position: relative;}
  .xzqItem{font-size: 15px;color: #FF0036;cursor: pointer;position: absolute;font-weight: bold;}
  .sheng{bottom: -2px;left: 48px;}
  .shi{bottom: 25px;left: 48px;}
  .qu{bottom: 63px;left: 48px;}
  .zheng{bottom: 102px;left: 48px;}
</style>
<script>
export default {
  name: 'OLMOVE',
  props: {
    map: {
      type:Object,
      default:null
    },
    view:{
      type:Object,
      default:null
    },
  },
  data () {
      return {
        value:10,
        listen:false,
      }
  },
  mounted() {
    this.loadItem=null
  },
  watch:{
    value(nval,oval){
      this.view.setZoom(nval)
    },
    view(nval,oval){
      if(!this.listen){
        this.view.on("change",e=>{
            this.value= parseInt(this.view.getZoom())
            //console.log(this.view.getZoom())
        })
        this.listen=true
        setTimeout(()=>{
            this.value= parseInt(this.view.getZoom())
        },500)
      }
    }
  },
  methods:{
    setZoom(zoom){
      this.value=zoom
      this.view.setZoom(zoom)
    },
    //平移
    moveToRight(){
      var mapCenter = this.view.getCenter();
      var mapZoom=this.view.getZoom()
      var vl=mapZoom
      mapCenter[0] -= 50 * Math.pow(vl, 11 - mapZoom);
      this.view.animate({
          center: mapCenter,
          duration: 250
      })
    },
    moveToLeft(){
      var mapCenter = this.view.getCenter();
      var mapZoom=this.view.getZoom()
      var vl=mapZoom
      mapCenter[0] += 50 * Math.pow(vl, 11 - mapZoom);
      this.view.animate({
          center: mapCenter,
          duration: 250
      })
    },
    moveToTop(){
      var mapCenter = this.view.getCenter();
      var mapZoom=this.view.getZoom()
      var vl=mapZoom
      mapCenter[1] += 50 * Math.pow(vl, 11 - mapZoom);
      this.view.animate({
          center: mapCenter,
          duration: 250
      })
    },
    moveToBottom(){
      var mapCenter = this.view.getCenter();
      var mapZoom=this.view.getZoom()
      var vl=mapZoom
      mapCenter[1] -= 50 * Math.pow(vl, 11 - mapZoom);
      this.view.animate({
          center: mapCenter,
          duration: 250
      })
    },
  }
}
</script>

